@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
body {
  margin: 0;
}


body, .App, button {
  padding: 2px;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

h1 {
  margin: 10px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  background: #E4E4FF;
  border: 1px solid #888;
  border-radius: 7px;
  margin: 5px 5px 5px 0px;
  padding: 1px 5px;
  cursor: pointer;
}

.fill {
  flex-grow: 1;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: #fafaff;
}

.DailyChart {
  font-size: 10pt;
  display: flex;
  flex-flow: column nowrap;
}

.DailyChart-content {
  display: grid;
  grid-template-columns: -webkit-min-content -webkit-min-content minmax(80px, 1fr) minmax(20ch, 2fr);
  grid-template-columns: min-content min-content minmax(80px, 1fr) minmax(20ch, 2fr);
}

.DailyChart-content > div:nth-child(4n + 1),
.DailyChart-content > div:nth-child(4n + 2),
.DailyChart-content > div:nth-child(4n + 3) {
  border-bottom: 1px solid #ccc;
}

/*
.DailyChart-content > div:nth-child(8n + 7) {
  background: linear-gradient(90deg, #F4F4FF, #FFFFFF);
}
*/

.DailyChart-content .year {
  text-align: left;
  margin-bottom: 0px;
  font-size: 14pt;
  font-weight: bold;
}

.DailyChart-content .week {
  font-size: 8pt;
  white-space: nowrap;
  padding: 2px 2px 2px 0px;
}

.DailyChart-content .days {
  padding: 2px 6px 2px 2px;
  text-align: right;
  white-space: nowrap;
}

.DailyChart-content .counts {
  padding: 2px 0px;
}

.DailyChart-content .notes {
  white-space: wrap;
  padding: 1px 1em 1px 0.8em;
  margin: 0px;
}

.note {
  padding-bottom: 2px;
}

.alt {
  background: #f0f9ff;
  /*
  background: linear-gradient(90deg, #F4F4FF, #FFFFFF);
  */
}

.oneline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* select only under DailyChart-content (.counts)? */
.bar {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bar-label {
  padding-right: 5px;
  text-align: right;
}

.arrow-down {
  font-size: 8pt;
  margin: 0px 3px 0px 0px;
  padding: 1px 3px 0px 2px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: #eeeeff;
}
