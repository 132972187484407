@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

body, .App, button {
  padding: 2px;
  font-family: 'Roboto Condensed', 'Roboto', sans-serif;
}

h1 {
  margin: 10px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
